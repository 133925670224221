
export default function utility_data() {
	update_scroll_values()
	window.addEventListener('viewport-update', update_resize_values);
	document.addEventListener('scroll', update_scroll_values);
	return {
		url: window.location.href,
		browser_height: isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight,
		browser_width: isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight,
		resizeTimeout: null,
		resizeThrottle: true,
		scrollTimeout: null,
		scrollThrottle: true,
	}
};

export function get_utility_data() {
	return {
		url: window.location.href,
		browser_height: isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight,
		browser_width: isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight,
		resizeTimeout: null,
		resizeThrottle: true,
		scrollTimeout: null,
		scrollThrottle: true,
	}
}

function update_scroll_values(){
	// const d = document;
	const de = document.documentElement;
	const b = document.body;
	// const w = window;
	// const utility_elements = {
	// 	body: d.querySelectorAll("body")[0]
	// }
	utility_data.scrollTop = b.scrollTop || de.scrollTop;
	utility_data.scrollQuart = utility_data.scrollTop + (utility_data.browser_height/4);
	utility_data.scrollMid = utility_data.scrollTop + (utility_data.browser_height/2);
	utility_data.scrollBotQuart = utility_data.scrollTop + ((utility_data.browser_height/4)*3);
	utility_data.scrollBot = utility_data.scrollTop + utility_data.browser_height;
};

function update_resize_values(){
	update_scroll_values()
	utility_data.browser_height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
	utility_data.browser_width = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
};
