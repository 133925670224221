import React, { Component } from "react";
import { Link } from 'gatsby';

import Logo from '../../assets/svg/charles_logo_text.svg';


let time_out = null;

export default class MobileHeader extends Component {
	constructor(props){
		super(props);
		this.state = {
			menu_opening: false,
			menu_opened: false,
			show_background: '',
			blendClass: null
		};
		this.toggle_menu = this.toggle_menu.bind(this);
		this.render_links = this.render_links.bind(this);
	}

	componentDidMount() {
		document.body.classList.remove('dark-text')
		this.setState({ show_background: '' })
		if (window.location.pathname === '/about') this.setState({ blendClass: 'about-color' })
		if (window.location.pathname === '/contact/new-business' || document.body.classList.contains('dark-text')) this.setState({ show_background: 'show-background-white' })
		else this.setState({ blendClass: null })

		document.addEventListener('scroll', this.scroll_handler);
	}
	componentWillUnmount() {
		document.removeEventListener('scroll', () => this.scroll_handler());
	}
	toggle_menu(event){
		clearTimeout(time_out)
		this.state.menu_opened ? this.close_menu() : this.open_menu();
	}
	scroll_handler = () => {
		if (window.pageYOffset > 80 && this.state.show_background === '') this.setState({ show_background: 'show-background' })
		else if (window.pageYOffset <= 80 && this.state.show_background === 'show-background') this.setState({ show_background: '' })
	}
	open_menu(){
		this.setState({
			menu_opening: true
		});
		time_out = setTimeout(function(){
			this.setState({
				menu_opened: true
			});
		}.bind(this), 100)
	}
	close_menu(){
		this.setState({
			menu_opened: false
		});
		time_out = setTimeout(function(){
			this.setState({
				menu_opening: false
			});
		}.bind(this), 300)
	}
	render_links(){
		if(this.props.links){
			var items = this.props.links[0].items;
			return items.map((link) => link.classes.includes("center-logo") ? false :
				<li key={link.title} className={link.classes}>
					<Link activeClassName='active' onClick={this.toggle_menu} to={link.url} title={ link.title }>
						<span className="styling-cta">
							{link.classes.includes("center-logo") ? <Logo/> : link.title }
						</span>
					</Link>
				</li>
			);
		}
	}
	render() {
		const openClass = this.state.menu_opening ? "mobile-header menu-opening" : "mobile-header";
		const openingClass = this.state.menu_opened ? " menu-open" : "";
		return <div className={openClass + openingClass + ' ' + this.state.show_background + ' ' + this.state.blendClass}>
			<div className="navigation grid">
				<ul className="nav-list grid-m-6 grid-t-16 grid-d-hide">
					<li className="center-logo">
						<a href="/" title="Go home">
							<Logo/>
						</a>
					</li>
					<li className="hamburger-icon-contain">
						<div className="hamburger-icon">
							<div className="bar"></div>
							<div className="bar"></div>
							<button aria-label="toggle menu" onClick={this.toggle_menu}></button>
						</div>
					</li>
				</ul>
			</div>
			<div className="menu grid">
				<ul className="menu-list grid-m-6 grid-t-16 grid-d-16">
					{this.render_links()}
					<li>
						<Link to="/contact/new-business" onClick={this.toggle_menu} title="Get in touch for a new business">
							<span className="button">Work with us</span>
						</Link>
					</li>
				</ul>
			</div>
		</div>;
	}
}
