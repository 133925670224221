import React, { Component } from "react";
import { Link } from 'gatsby';

import Desktop_Navigation_Panel from "./Desktop_Nav--panel.js";
import Logo from "../../assets/svg/charles_logo_text.svg";


export default class Desktop_Navigation_Link extends Component {
	constructor(props){
		super(props);
		this.state = {
			linkInteractionThrottle: null,
			panelInteractionThrottle: null,
			timer: 300,
			interacting: false,
			interactingWithLink: false,
			interactingWithPanel: false,
			highlightLink: false,
			openPanel: false
		};
	}
	determineInteraction = () => {
		if(this.state.interactingWithLink || this.state.interactingWithPanel){
			this.interactingWithGroup();
		} else if(!this.state.interactingWithLink && !this.state.interactingWithPanel){
			this.notInteractingWithGroup();
		}
	}
	interactingWithGroup = () => {
		this.setState({
			interacting: true,
			highlightLink: true,
			openPanel: true
		});
		setTimeout(()=>{
			this.props.parentInteraction()
		}, this.state.timer)
	}
	notInteractingWithGroup = () => {
		this.setState({
			interacting: false,
			highlightLink: false,
			openPanel: false
		});
		this.props.parentUninteraction()
	}
	interactingWithLink = () => {
		clearTimeout(this.state.linkInteractionThrottle);
		this.state.linkInteractionThrottle = setTimeout(()=>{
			this.setState({
				interactingWithLink: true
			});
			this.determineInteraction()
		}, 0);
	}
	notInteractingWithLink = () => {
		clearTimeout(this.state.linkInteractionThrottle);
		this.state.linkInteractionThrottle = setTimeout(()=>{
			this.setState({
				interactingWithLink: false
			});
			this.determineInteraction()
		}, this.state.timer);
	}
	interactingWithPanel = () => {
		clearTimeout(this.state.panelInteractionThrottle);
		this.state.panelInteractionThrottle = setTimeout(()=>{
			this.setState({
				interactingWithPanel: true
			});
			this.determineInteraction()
		}, 0);
	}
	notInteractingWithPanel = () => {
		clearTimeout(this.state.panelInteractionThrottle);
		this.state.panelInteractionThrottle = setTimeout(()=>{
			console.log("not interacting w/ panel");
			this.setState({
				interactingWithPanel: false
			});
			console.log(this.state.interactingWithLink, this.state.interactingWithPanel);
			this.determineInteraction()
		}, this.state.timer);
	}
	generatePanel = () => {
		if(this.props.title.toUpperCase() === "CONTACT"){
			return <Desktop_Navigation_Panel />;
		}
		return null;
	}
	generateUserRunway = () => {
		if(this.props.title.toUpperCase() === "CONTACT"){
			return <div className="user-runway">
				<div className="runway wide-left"></div>
				<div className="runway left"></div>
				<div className="runway near-left"></div>
				<div className="runway center-left"></div>
				<div className="runway center"></div>
				<div className="runway center-right"></div>
				<div className="runway near-right"></div>
				<div className="runway right"></div>
				<div className="runway wide-right"></div>
			</div>;
		}
		return null;
	}
	render(){
		let highlight = this.state.highlightLink ? " interacting" : "" ;
		let openPanel = this.state.openPanel ? " open-panel" : "" ;
		return <li key={ this.props.title } className={ this.props.classes + " navigation-link" + highlight }>
			<Link activeClassName='active' to={ this.props.url } title={ this.props.title }>
				<span onMouseOver={this.interactingWithLink} onMouseLeave={this.notInteractingWithLink} className="styling-cta">
					{this.props.classes.includes("center-logo") ? <Logo/> : this.props.title }
					{this.generateUserRunway()}
				</span>
			</Link>
			<div onMouseOver={this.interactingWithPanel} onMouseLeave={this.notInteractingWithPanel}  className={ "dropdown-container" + openPanel }>
				{this.generatePanel()}
			</div>
		</li>;
	}
}
