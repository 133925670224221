import React, { Component } from "react";
import { ArrowLink } from "../buttons/Arrow_Link.js";

export default class GDPR extends Component {
	componentDidMount() {
        this.closed = false
        this.cookieName = 'GDPRNotice'
        this.$banner = document.querySelector('.gdpr')
        const rawCookies = document.cookie
        const bakedCookies = {}
        const $button = document.querySelector('.JS-GDPR-button')

        rawCookies.split(';').forEach(i => {
            i = i.split('=')
            bakedCookies[i[0]] = i[1]
        })

        this._closeBanner = this._closeBanner.bind(this)
        $button.addEventListener('click', this._closeBanner)
        $button.addEventListener('touchend', this._closeBanner)

        this._checkForCookie(bakedCookies.GDPRNotice)
    }

    _getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin === -1) {
            begin = dc.indexOf(prefix);
            if (begin !== 0) return null;
        }
        else
        {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end === -1) {
                end = dc.length;
            }
        }
        return decodeURI(dc.substring(begin + prefix.length, end));
    } 

    _checkForCookie(hasCookie) {
        const cookie = this._getCookie(this.cookieName)
        if (!cookie) this._displayBanner()
    }

    _displayBanner() {
        this.$banner.classList.add('displayGDPRNotice')
    }

    _closeBanner() {
        if (!this.closed) {
			let d = new Date();
			d.setTime(d.getTime() + (365*24*60*60*1000));
            this.closed = true;
            document.cookie = this.cookieName + '=true;expires='+d.toUTCString();
            this.$banner.classList.remove('displayGDPRNotice');
        }
    }

	render() {
        return (
            <div className="gdpr">
                <div className="grid">
                    <div className="grid-d-12 grid-t-8 grid-m-4 gdpr__flex">
                        <p className="gdpr__text">{this.props.data ? this.props.data[0].acf.banner : 'This website uses cookies, including third-party cookies, which allow The Charles to obtain information about your visit to the website. Please click here to learn more about the cookies used on this website and how to change current settings if you do not agree. By continuing to use this website (clicking on a picture or link under this banner), you consent to the use of cookies.'}</p>
                        <div className="gdpr__buttons">
                            <button className="JS-GDPR-button button gdpr__button">Close</button>
                            <ArrowLink title="More information" url={'/cookie-policy'} />
                        </div>
                    </div>
                </div>
            </div>
        )
	}
}
