import React from "react";
import { Link } from 'gatsby';

import ArrowHead from "../../assets/svg/arrow-head.svg";


function Text(props){
	if(props.title){
		return <span className="text">{props.title}</span>;
	}
	return false;
}

function Arrow(){
	return <div className="arrow">
		<div className="arrow-shaft"></div>
		<div className="arrow-head">
			<ArrowHead />
		</div>
	</div>;
}

const ArrowButton = props => {
	return <span tabIndex="-1" className={ "arrow-button " + (props.color || "white") +" "+ (props.alignment || "left")}>
		<button aria-label={props.title + " button"} ></button>
		<Text title={props.title} />
		<Arrow />
	</span>;
}

const ArrowLink = props => {
	if( !props.url.includes("http://") &&  !props.url.includes("https://") ){
		return <Link to={props.url} tabIndex="-1" className={ "arrow-button " + (props.color || "white") +" "+ (props.alignment || "left")} target={props.url.match(/^\/[a-z]/g) ? null : '_blank'} title="Access to case study">
			<Text title={props.title} />
			<Arrow />
		</Link>;
	} else{
		return <a href={props.url} tabIndex="-1" className={ "arrow-button " + (props.color || "white") +" "+ (props.alignment || "left")} target={props.url.match(/^\/[a-z]/g) ? null : '_blank'} title="Access to case study">
			<Text title={props.title} />
			<Arrow />
		</a>;
	}
}

export {ArrowButton, ArrowLink};
