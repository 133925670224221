import React, { Component } from "react";

import utility_data from "../utilities/browser_information.js";

import DesktopNav from "./Desktop_Nav.js";
import MobileNav from "./Mobile_Nav.js";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import "../../assets/scss/site.scss"

export default class Navigation extends Component {
	constructor(props){
		super(props);
		this.links = this.props.links
		this.state = {
			show_background: false,
		};
		this.scroll_handler = this.scroll_handler.bind(this);
	}
	componentDidMount(){
		setTimeout(() => document.addEventListener('scroll', this.scroll_handler), 900);
	}
	componentWillUnmount(){
		document.removeEventListener('scroll', this.scroll_handler);
	}
	componentWillReceiveProps() {
		for (const el of Array.from(document.querySelectorAll('.in-view'))) el.classList.remove('in-view')
		this.setState({ show_background: false })
	}
	scroll_handler(){
		if(utility_data.scrollTop > 10 && this.state.show_background === false){
			this.setState({
				show_background: true
			});
		}
		if(utility_data.scrollTop < 10 && this.state.show_background === true){
			this.setState({
				show_background: false
			});
		}
	}
	render(){
		// let page_ready = this.state.isLoaded ? "page-ready" : "page-loading";
		let showBackgroundClass = this.state.show_background ? " show-background" : "";
		return (
			<div className="all-navs">
				<DesktopNav links={this.links} show_background={showBackgroundClass} />
				<MobileNav links={this.links} show_background={showBackgroundClass} />
			</div>
		);
	}
}